/*eslint-disable*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Support.module.css';

function Support({ closeModal }) {
  const { t } = useTranslation();
  return (
    <div className={styles.backDrop}>
      <div className={styles.supportModal}>
        <div className={styles.m1}>
          {/* <span className={styles.xyz}>X</span> */}
          <img onClick={closeModal} className={styles.closeIcon} src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/close_glyphs_white@1x.png" alt="close" />
          <table id={styles.emailTable}>
            <tr>
              <th>{t('country')}</th>
              <th>{t('email')}</th>
            </tr>
            <tr>
              <td>{t('belgium')}</td>
              <a className={styles.emailContainer} href="mailto:be.einvoicesupport@amexgbt.com">
                <img className={styles.emailIcon} src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/email_active@1x.png" alt="Email Icon" />
                <span className={styles.emailText}>be.einvoicesupport@amexgbt.com</span>
              </a>

            </tr>
            <tr>
              <td>{[t('denmark'),", ", t('norway')," & ", t('sweden')]}</td>
              <a className={styles.emailContainer} href="mailto:nordic.e-invoicesupport@amexgbt.com">
                <img className={styles.emailIcon} src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/email_active@1x.png" alt="Email Icon" />
                <span className={styles.emailText}>nordic.e-invoicesupport@amexgbt.com</span>
              </a>

            </tr>
            <tr>
              <td>{t('france')}</td>
              <a className={styles.emailContainer} href="mailto:fr.einvoicesupport@amexgbt.com">
                <img className={styles.emailIcon} src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/email_active@1x.png" alt="Email Icon" />
                <span className={styles.emailText}>fr.einvoicesupport@amexgbt.com</span>
              </a>

            </tr>
            <tr>
              <td>{t('germany')}</td>
              <a className={styles.emailContainer} href="mailto:de.einvoicesupport@amexgbt.com">
                <img className={styles.emailIcon} src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/email_active@1x.png" alt="Email Icon" />
                <span className={styles.emailText}>de.einvoicesupport@amexgbt.com</span>
              </a>

            </tr>
            <tr>
              <td>{t('netherlands')}</td>
              <a className={styles.emailContainer} href="mailto:nl.einvoicesupport@amexgbt.com">
                <img className={styles.emailIcon} src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/email_active@1x.png" alt="Email Icon" />
                <span className={styles.emailText}>nl.einvoicesupport@amexgbt.com</span>
              </a>

            </tr>
            <tr>
              <td>{t('switzerland')}</td>
              <a className={styles.emailContainer} href="mailto:ch.einvoicesupport@amexgbt.com">
                <img className={styles.emailIcon} src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/email_active@1x.png" alt="Email Icon" />
                <span className={styles.emailText}>ch.einvoicesupport@amexgbt.com</span>
              </a>

            </tr>
            <tr>
              <td>{[t('uk'), ", ", t('ireland')]} </td>
              <a className={styles.emailContainer} href="mailto:e-invoicingsupport@amexgbt.com">
                <img className={styles.emailIcon} src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/email_active@1x.png" alt="Email Icon" />
                <span className={styles.emailText}>e-invoicingsupport@amexgbt.com</span>
              </a>

            </tr>
            <tr>
              <td>{t('spain')} </td>
              <a className={styles.emailContainer} href="mailto:es.traveladmin@amexgbt.com">
                <img className={styles.emailIcon} src="https://gbtcdnstorage.blob.core.windows.net/cdn1/brand19/icons/png/16x16/email_active@1x.png" alt="Email Icon" />
                <span className={styles.emailText}>es.traveladmin@amexgbt.com</span>
              </a>

            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Support;
